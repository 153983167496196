import { keepPreviousData, useQuery } from '@tanstack/react-query';

import useCart from '@/app/_store/shoping-cart';
import { diamondListQueryCreator } from '@/store/diamond';
import { emptyQueryCreator } from '@/store/general';
import { productListQueryCreator } from '@/store/product';

const useShopingCart = () => {
  const { list, addItem, removeItem, open, setOpen, removeAll } = useCart();

  const lists = list.reduce(
    (
      acc: {
        productVariantIds: number[];
        diamondIds: number[];
      },
      item,
    ) => {
      if ('diamondId' in item) {
        acc.diamondIds.push(item.diamondId);
      }

      if ('product' in item && item.product) {
        acc.productVariantIds.push(item.product.variantId);
      }

      return acc;
    },
    {
      productVariantIds: [],
      diamondIds: [],
    },
  );

  const productList = useQuery(
    lists.productVariantIds.length
      ? {
          ...productListQueryCreator({
            variantIds: lists.productVariantIds,
          }),

          placeholderData: keepPreviousData,
        }
      : emptyQueryCreator(),
  );

  const diamondList = useQuery(
    lists.diamondIds.length
      ? {
          ...diamondListQueryCreator({ diamondIds: lists.diamondIds }),
          placeholderData: keepPreviousData,
        }
      : emptyQueryCreator(),
  );

  const products = new Map(
    productList.data?.data?.map((product) => [product.id, product]),
  );

  const diamonds = new Map(
    diamondList.data?.data?.map((diamond) => [diamond.id, diamond]),
  );

  list.forEach((cartItem) => {
    if (
      'diamondId' in cartItem &&
      !diamonds.get(cartItem.diamondId) &&
      !diamondList.isFetching
    ) {
      removeItem(cartItem.id);
    }

    if (
      'product' in cartItem &&
      (!cartItem.product ||
        (!products.get(cartItem.product.variantId) && !productList.isFetching))
    ) {
      removeItem(cartItem.id);
    }
  });

  const fullList = list.map((item) => ({
    ...item,
    data: {
      product:
        'product' in item && item.product
          ? products.get(item.product.variantId)
          : null,
      diamond: 'diamondId' in item ? diamonds.get(item.diamondId) : null,
    },
  }));

  return {
    fullList,
    addItem,
    removeItem,
    open,
    setOpen,
    removeAll,
    isLoading: productList.isLoading || diamondList.isLoading,
    isFetched: productList.isFetched || diamondList.isFetched,
  };
};

export default useShopingCart;
