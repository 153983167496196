import { JEWELRY, MATCHING_JEWELRES } from '@/config/routes';

export const getSlug = (str: string) =>
  str
    .toLowerCase()
    .replace(/[().,/*%$#@!?><;:'"|]/g, '')
    .replaceAll(' ', '-');

export const splitSelfDomain = (link: string) => {
  const domain = process.env.NEXT_PUBLIC_DOMAIN || '';
  const regex = new RegExp(`^https?://${domain}`);

  if (regex.test(link)) {
    return link.replace(regex, '');
  }
  return link;
};

export const getVariantSlug = (data: {
  shape?: string | null;
  metal: string;
  variantId: number;
}) => {
  const slug: string[] = [];
  if (data.shape) {
    slug.push(data.shape);
  }
  slug.push(data.metal, data.variantId.toString());
  return slug.map((item) => getSlug(item)).join('-');
};

export const getVariantUrl = (
  data: {
    productSlug: string;
    shape?: string | null;
    metal: string;
    variantId: number;
    categorySlug: string;
  },
  jewelryBuilder?: { diamondId: number },
) => {
  const path = jewelryBuilder
    ? [MATCHING_JEWELRES.fullPath, jewelryBuilder.diamondId]
    : [JEWELRY.fullPath, data.categorySlug || 'category', data.productSlug];

  const slug = getVariantSlug(data);
  path.push(slug);

  return path.join('/');
};
