import { create } from 'zustand';

interface Notification {
  type: 'error' | 'warning' | 'info';
  content: string;
  id: string;
}

interface State {
  list: Notification[];

  addNotification: (notification: Partial<Omit<Notification, 'id'>>) => void;
  removeNotification: (id: string) => void;
}

const useNotifications = create<State>((set) => ({
  list: [],
  addNotification(notification) {
    set((state) => ({
      list: [
        ...state.list,
        {
          content: notification.content || '',
          type: notification.type || 'info',
          id: Date.now().toString(),
        },
      ],
    }));
  },
  removeNotification(id) {
    set((state) => ({ list: state.list.filter((item) => item.id !== id) }));
  },
}));

export default useNotifications;
