import React from 'react';

import { IconButton } from '@mui/material';
import classNames from 'classnames';
import Link from 'next/link';

import { DIAMONDS, JEWELRY } from '@/config/routes';
import { handlePrice } from '@/helpers/measurements';
import { getVariantUrl } from '@/helpers/url';
import CloseIcon from '@/public/icons/x.svg';
import img from '@/public/images/hand-with-ring.svg?url';
import { Diamond } from '@/types/diamond';
import { ListProduct } from '@/types/product';

import Image from '../../image';

import classes from './index.module.scss';

interface ItemProps {
  product?: ListProduct | null;
  diamond?: Diamond | null;
  onRemove?: () => void;
  compact?: boolean;
}

const Item: React.FC<ItemProps> = (props) => {
  const { diamond, product, onRemove, compact = false } = props;

  const totalPrice = (product?.price || 0) + (diamond?.totalPrice || 0);

  return (
    <div className={classNames(classes.item, { [classes.compact]: compact })}>
      {!compact && (
        <div className={classes.head}>
          <div className={classes.nameWrap}>
            {onRemove && (
              <IconButton onClick={onRemove}>
                <CloseIcon />
              </IconButton>
            )}

            <span className={classes.name}>
              Your{' '}
              {product && (product.product.categories[0].modifier || 'setting')}
              {!product && diamond && 'Diamond'}
            </span>
          </div>
          <div className={classes.totalPrice}>{handlePrice(totalPrice)}</div>
        </div>
      )}

      {product && (
        <div>
          <div className={classes.row}>
            <Link
              href={getVariantUrl({
                categorySlug: product.product.categories[0].slug,
                metal: product.metal.title,
                productSlug: product.product.slug,
                shape: product.centralProductShape?.shape.title,
                variantId: product.id,
              })}
              className={classes.imgWrap}
            >
              <Image
                src={product.imageUrls[0]}
                alt=""
                width={150}
                height={150}
                fallback
              />
            </Link>
            <div className={classes.info}>
              <div className={classes.topInfo}>
                <div>Setting:</div>
                <div>{handlePrice(product.price)}</div>
              </div>
              <div>
                <Link
                  className={classes.link}
                  href={getVariantUrl({
                    categorySlug: product.product.categories[0].slug,
                    metal: product.metal.title,
                    productSlug: product.product.slug,
                    shape: product.centralProductShape?.shape.title,
                    variantId: product.id,
                  })}
                >
                  {product.product.title}
                </Link>
              </div>
              <div>Details:</div>
              <div>{product.metal.title}</div>
            </div>
          </div>
        </div>
      )}

      {diamond && (
        <div>
          <div className={classes.row}>
            <Link
              href={`${DIAMONDS.fullPath}/${diamond.id}`}
              className={classes.imgWrap}
            >
              <Image
                src={
                  diamond.imageUrls && diamond.imageUrls.length
                    ? diamond.imageUrls[0]
                    : ''
                }
                alt=""
                width={150}
                height={150}
                fallback
              />
            </Link>
            <div className={classes.info}>
              <div className={classes.topInfo}>
                <div>Diamond:</div>
                <div>
                  {diamond.totalPrice && handlePrice(diamond.totalPrice)}
                </div>
              </div>

              <div>Shape: {diamond.shape?.title}</div>
              <div>ID: {diamond.shape?.id}</div>
              <div>Cut: {diamond.cut}</div>
              <div>Clarity: {diamond.clarity}</div>
              <div>Carat: {diamond.carat}</div>
              <div>Color: {diamond.color}</div>
            </div>
          </div>
        </div>
      )}
      {diamond && !product && false && (
        <div className={classes.row}>
          <div className={classes.imgWrap}>
            <Image src={img} alt="" width={88} height={100} fallback />
          </div>
          <div className={classes.info}>
            <div className={classes.topInfo}>
              <div>This diamond needs a setting. Select one to checkout.</div>
            </div>
            <Link className={classes.link} href={JEWELRY.fullPath}>
              SELECT SETTING
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Item;
