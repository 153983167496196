export interface IConfig<SearchParams extends Record<string, string> = {}> {
  path: string;
  name: string;
  parent?: Route;
  searchParams?: SearchParams;
}

const routes: Route[] = [];

export class Route<SearchParams extends Record<string, string> = {}> {
  path: string;

  name: string;

  fullPath: string;

  searchParams: SearchParams;

  constructor(config: IConfig<SearchParams>) {
    this.path = config.path;
    this.fullPath = `${config.parent ? config.parent.fullPath : ''}/${config.path}`;
    this.name = config.name;
    this.searchParams = config.searchParams || ({} as SearchParams);

    routes.push(this);
  }
}

export const HOME = new Route({
  name: 'Home',
  path: '',
});

export const JEWELRY = new Route({
  name: 'Jewelry',
  path: 'jewelry',
  searchParams: { shapeId: 'shapeId' },
});

export const DIAMONDS = new Route({
  name: 'Diamonds',
  path: 'diamonds',
  searchParams: {
    shape: 'shape',
    caratMin: 'caratMin',
    caratMax: 'caratMax',
    diamondId: 'diamond-id',
  },
});

export const DIAMOND_SEARCH = new Route({
  name: 'Diamonds',
  path: 'diamond-search',
});

export const WISHLIST = new Route({
  name: 'WISHLIST',
  path: 'wishlist',
});

export const COMPARISON = new Route({
  name: 'Comparison',
  path: 'comparison',
});

export const MATCHING = new Route({
  name: 'Matching',
  path: 'matching',
});

export const MATCHING_DIAMONDS = new Route({
  name: 'Diamonds',
  path: 'jewelry-to-diamond',
  parent: MATCHING,
});

export const MATCHING_JEWELRES = new Route({
  name: 'Jewelry',
  path: 'diamond-to-jewelry',
  parent: MATCHING,
});

export const ETIQUETTE = new Route({
  name: 'Etiquette',
  path: 'etiquette',
});

export const WARRANTY = new Route({
  name: 'Lifetime Warranty',
  path: 'lifetime-warranty',
});

export const SHIPPING_POLICY = new Route({
  name: 'Shipping Policy',
  path: 'shipping-policy',
});

export const RETURN_POLICY = new Route({
  name: 'Return Policy',
  path: 'return-policy',
});

export const CHECKOUT = new Route({
  name: 'Checkout',
  path: 'checkout',
});

export default routes;
